@import url('https://fonts.googleapis.com/css?family=Bellota|Prompt|Montserrat&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a {
  text-decoration: none; 
  color: #373837; 
}
a:hover {
  color:#D6AC56;
}
.aboutImg {
  width: 300px; 
  margin-top: 100px; 
  margin-bottom: 50px; 
  border-radius: 5px; 
  box-shadow: 12px 12px #FFD985;
}

p, li {
  font-family: 'Montserrat', sans-serif; 
  color: #296659;
}

.pageTitle {
  font-weight: normal; 
  display: inline; 
  margin: 0 auto; 
  border-bottom: 1px solid #BEEBD9;
}

#header {
  text-align: center; 
  background-color: #BEEBDA;
  height: 400px;  
  box-shadow: 0 0 10px lightgrey; 
}
#header h1 {
  margin: 0; 
  padding: 10px; 
  font-size: 2.5em;
}
#header a {
  color: #0d4c40;  
}
h1, h2, h3, h4 {
  font-family: 'Prompt', cursive; 
  color: #296659;
}
#header ul {
  list-style-type: none; 
  margin: 0; 
  padding: 0; 
}

.babyImage {
  background-image: url("AdobeStock_297479660.jpeg");
  height: 400px; 
  width: auto; 
  background-position: center; 
  background-size:cover; 
  margin: -30px -30px 30px -30px; 
}
.babyImage h2{
  position: absolute; 
  top: 530px;  
  margin: 0 10px; 
  padding: 5px; 
  text-align: center; 
  font-weight: bold; 
  font-size: 30px; 
  color: white; 
}
#header li a {
  display: block; 
  padding: 16px; 
  text-align: center; 
  color: #2A6659; 
}

#header li a:hover{
  font-weight: bold; 
}
.smallcontainer {
  display: grid; 
  justify-items: left; 
  grid-template-columns: 100%;
}

.eatingImg {
  width: 100%;
  justify-self: center; 
  border-radius: 5px; 
  box-shadow: 12px 12px #FFD985;
}
#feedingTips {
  text-align: left; 
}
.centerHighlight {
  text-align: center; 
  font-weight: normal; 
  margin: 60px; 
}
.container {
  display: grid; 
  justify-items: center; 
  grid-row-gap: 25px; 
  grid-column-gap: 10px; 
}
#resourceContainer {
  text-align: left; 
  justify-items: left;  
}
.content {
  padding: 30px; 
}
.content h1 {
  text-align: center; 
}
.text {
  margin: 0 auto; 
  font-size: 1.2em;
  text-align: left;
}
#homePage {
  text-align: center; 
}

.section {
  width: 300px; 
  height: 320px;
  border: 2px solid lightgrey;  
  border-radius: 5px; 
  box-shadow: 12px 12px #FFD985;
}
.section p {
  font-size: 15px; 
  padding: 5px; 
}


.infoLeft {
  text-align: left; 
  margin-bottom: 20px;  
}
.infoLeft h2{
  text-align: left; 
}


.resourceLinks {
  display: grid; 
  grid-template-columns: 25px 200px; 
  grid-row-gap: 20px; 
}
.resourceLinks a {
  color: #EEAC29;
}

.resourceLinks a:hover {
  text-decoration: underline;
}

.nutrients div {
  border-radius: 5px; 
  height: auto; 
  width: 90%;  
  text-align: left;
  box-shadow: 0 0 10px lightgrey;
}
.nutrients div p, .nutrients div li {
  padding: 15px; 
}
.powerhouseContainer {
  display: grid; 
  grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%; 
  grid-template-rows: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%; 
  justify-content: center; 
  align-content: center; 
  box-shadow: 0 0 10px lightgrey;
  margin: auto; 
}
.checkmark {
  color: #EDAC29;
  font-size: 20px; 
}
.header h3 {
  font-size: 0.6em; 
  color: white; 
  font-weight: bold;
}
.header{
  background-color: #4A8076;
}
.mealsdiv h2 {
  text-align: left; 
}
.recipe {
  text-align: left;
  border-radius: 5px; 
  box-shadow: 0 0 10px lightgrey; 
}
.recipe p {
  padding: 20px; 
  margin: 0; 
}

#recipesPagePic, #dadAndBabyPic {
  width: 100%; 
  display: block; 
  margin: 20px auto;   
}

li {
  text-align: left; 
}
#food1 {
  grid-column-start: 2; 
}
#food2 {
  grid-column-start: 3; 
}
#food3 {
  grid-column-start: 4; 
}
#food4 {
  grid-column-start: 5; 
}
#food5 {
  grid-column-start: 6; 
}
#nut1 {
  grid-row-start: 2; 
}
#nut2 {
  grid-row-start: 3; 
}
#nut3 {
  grid-row-start: 4; 
}
#nut4 {
  grid-row-start: 5; 
}
#nut5 {
  grid-row-start: 6; 
}
#nut6 {
  grid-row-start: 7; 
}
#nut7 {
  grid-row-start: 8; 
}
.nutrientHeader {
  background: #4A8076;
  text-align: center; 
  color: white; 
  padding: 5px; 
  margin-top: 0; 
}
.easyMealsContainer {
  display: grid; 
  grid-template-columns: 100%;
  text-align: left;  
}
.aboutcontainer {
  display: grid; 
  grid-template-columns: 100%; 
  align-items: center; 
  justify-items: center; 
}
#headerContainer {
  display: grid; 
  grid-template-columns:100%; 
  height: 110px; 
  justify-items: center; 
  align-items: center; 
}

.s1{
  background-image: url("https://www.unicef.org/parenting/sites/unicef.org.parenting/files/styles/hero_desktop/public/AdobeStock_166161324.jpeg?itok=w6XMDzoD");
  background-position: center; 
  background-size: cover; 
  height: 150px; 
  width: auto; 
}
.s2{
  background-image: url("https://www.rd.com/wp-content/uploads/2017/04/01-Nutrients-Even-Nutritionists-Dont-Get-Enough-Of-1024x683.jpg");
  background-position: center; 
  background-size: cover; 
  height: 150px; 
  width: auto; 
}
.s3{
  background-image: url("https://extendednotesimages.s3.amazonaws.com/channel_images/642/healthy_eating_main__800x400.jpg");
  background-position: center; 
  background-size: cover; 
  height: 150px; 
  width: auto; 
}
.section:hover {
  box-shadow: 12px 12px #BEEBDA;
  cursor: pointer; 
}
.recipeImg {
  height: 100px; 
  line-height: 100px; 
  background: teal; 
  text-align: center; 
}
.recipeTitle {
  font-size: 1.2em; 
  color: white; 
  margin: auto; 
}
.recipeDirections {
  border-top: 2px solid #EDF4F2; 
}
.recipeHeader {
  font-weight: bold; 
}

footer {
  background-color: #BEEBDA;
  padding: 20px; 
  margin: 20px 0 0 0; 
  text-align: left; 
  font-family: 'Bellota', cursive;
}
footer p {
  padding: 5px; 
  margin: 0; 
}
footer a {
  font-weight: bold; 
  color: #0d4c40;
  text-decoration: underline; 
}
footer a:hover{
  color: #EEAC29; 

}
@media (min-width: 600px){
  .content {
    margin: 0 60px; 
  }
  .checkmark {
    font-size: 30px; 
  }
  
  .container {
    grid-template-columns: 33.33333% 33.33333% 33.33333%; 
    /* justify-content: center;  */
    grid-row-gap: 25px;  
    grid-column-gap: 17px;
  }
  .aboutcontainer {
    margin-top: 30px; 
    grid-template-columns: 40% 60%; 
  }
  .smallcontainer {
    grid-template-columns: 50% 40%;
    align-self: center; 
    grid-column-gap: 50px; 
    
  }
  .powerhouseContainer {
    width: 100%; 
  }

  .easyMealsContainer {
    grid-template-columns: 45% 45%;  
  }
  .eatingImg {
    width: 100%; 
    margin: 20px; 
  }
  .aboutImg {
    margin: 50px 0; 
  }

  #feedingTips {
    padding: 20px 0; 
    text-align: left; 
  }
  p {
    font-size: 16px; 
    color: #296659;
  }
  #header {
    height: 160px;
  }
  #header li {
    float: left; 
  }

  .header h3 {
    font-size: 1em; 
  }
  
  .babyImage {
    position: relative; 
    height: 700px; 
    margin: -30px -90px 30px -90px; 
    z-index: -2; 
  }
  .babyImage h2 {
    top: 450px; 
    left: 70px;
    width: 520px; 
    text-align: left; 
    font-size: 40px; 
    padding: 10px; 
    margin: 0; 
  }
 
  #headerContainer {
    grid-template-columns:100%; 
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
